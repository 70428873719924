import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { theme } from "../../../utils/global-styles/theme";
import { _post } from "../../../utils/services/api.service";
import DynamicForm from "../../shared/DynamicForm/DynamicForm";

const MarketingForm = ({ form }) => {
  const [emailError, setEmailError] = useState(null),
    [firstNameError, setFirstNameError] = useState(null),
    [lastNameError, setLastNameError] = useState(null),
    [newsletterMessage, setNewsletterMessage] = useState(""),
    FormGrid = styled.div`
      font-family: ${theme.fontFaces.headers};
      height: max-content;
      width: 518px;
      margin: 56px 120px -32px 0;
      padding: 63px 73px 56px 73px;
      display: grid;
      grid-template: 36px 1fr / 1fr;
      box-shadow: 0 2px 29px 0 rgba(0, 0, 0, 0.5);

      @media only screen and (max-width: ${theme.breakpoints.desktopUp}) {
        margin: -32px 0 0 0;
      }

      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        padding: 30px 14px;
      }

      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        margin: 0;
      }
    `,
    FormTitle = styled.h3`
      grid-area: 1 / 1 / span 1 / span 1;
      font-size: 30px;
      font-weight: bold;
      letter-spacing: -0.91px;
      line-height: 35px;
      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        font-size: 25px;
      }
    `,
    FormFieldsContainer = styled.div`
      grid-area: 2 / 1 / span 1 / span 1;
    `,
    FormConfirmButtonStyles = css`
      height: 36px;
      width: 105px;
      border-width: 1px;
      border-style: solid;
      margin-top: 20px;
      @media only screen and (max-width: ${theme.breakpoints.desktopUp}) {
        width: 100%;
      }
    `,
    FormConfirmButtonTextStyles = css`
      color: ${theme.colors.white};
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0.4px;
      line-height: 15px;
    `,
    FormStyles = css`
      grid-area: 1 / 1 / span 1 / span 1;

      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        margin: 0;
      }
    `,
    FormLabelStyles = css`
      font-weight: bold;
      font-family: ${theme.fontFaces.headers};
      font-size: 14px;
      letter-spacing: 0;
      line-height: 17px;
      position: relative;
      top: -0.5em;
      background-color: white;
      padding: 0 8px;
      left: 4px;
    `,
    FormWrapperStyles = css`
      border: 2px solid ${theme.colors.accent};
      height: 49px;
      width: 371px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px 0px 16px 12px;
      margin-top: 28px;

      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        width: 346px;
      }

      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        width: 100%;
      }
    `;

  const handleSubmit = async (event, value) => {
    const request = {
      first_name: value.firstName,
      last_name: value.lastName,
      email: value.email,
      category_id: "646",
    };
    const apiResponse = await _post({
      args: request,
      endpoint: "newsletters",
      isPublic: true,
    });
    if (apiResponse.success) {
      setNewsletterMessage("Thanks for signing up!");
    } else {
      setNewsletterMessage("An error occured, please try again.");
    }
    setFirstNameError(null);
    setLastNameError(null);
    setEmailError(null);
  };

  return (
    <FormGrid className="white-bg" id="form">
      <FormTitle>{form.heading}</FormTitle>
      <FormFieldsContainer>
        <DynamicForm
          formValues={[
            {
              name: "firstName",
              label: form.formFields[0],
              styles: {
                labelStyles: FormLabelStyles,
                valueStyles: css`
                  font-size: 14px;
                  font-weight: 500;
                  letter-spacing: 0;
                  line-height: 18px;
                `,
                wrapperStyles: FormWrapperStyles,
                labelClassNames: "primary",
              },
              value: "",
              inputType: "text",
              required: false,
              handleValidation: (event, setError) => {
                if (!event.target.value.length) {
                  setError("You must enter your first name.");
                } else {
                  setError(null);
                }
              },
              error: firstNameError,
            },
            {
              name: "lastName",
              label: form.formFields[1],
              styles: {
                labelStyles: FormLabelStyles,
                valueStyles: css`
                  font-size: 14px;
                  font-weight: 500;
                  letter-spacing: 0;
                  line-height: 18px;
                `,
                wrapperStyles: FormWrapperStyles,
              },
              value: "",
              inputType: "text",
              required: false,
              handleValidation: (event, setError) => {
                if (!event.target.value.length) {
                  setError("You must enter your last name.");
                } else {
                  setError(null);
                }
              },
              error: lastNameError,
            },
            {
              name: "email",
              label: form.formFields[2],
              styles: {
                labelStyles: FormLabelStyles,
                valueStyles: css`
                  font-size: 14px;
                  font-weight: 500;
                  letter-spacing: 0;
                  line-height: 18px;
                `,
                wrapperStyles: FormWrapperStyles,
              },
              value: "",
              inputType: "text",
              inputSubType: "email",
              required: false,
              handleValidation: (event, setError) => {
                const emailRegex = new RegExp(
                  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );
                if (!emailRegex.test(event.target.value)) {
                  setError("You must enter a valid email address.");
                } else {
                  setError(null);
                }
              },
              error: emailError,
            },
          ]}
          confirmButton={{
            buttonText: form.cta[0].linkText,
            styles: {
              buttonStyle: FormConfirmButtonStyles,
              buttonTextStyles: FormConfirmButtonTextStyles,
            },
            confirmButtonClasses: `primary-bg primary-border`,
          }}
          submit={handleSubmit}
          formStyles={FormStyles}
          finePrint={newsletterMessage}
        />
      </FormFieldsContainer>
    </FormGrid>
  );
};

MarketingForm.propTypes = {
  form: PropTypes.shape({
    heading: PropTypes.string,
    formFields: PropTypes.arrayOf(PropTypes.string),
    cta: PropTypes.arrayOf(
      PropTypes.shape({
        linkText: PropTypes.string,
      })
    ),
  }),
};

export default MarketingForm;
