import React from "react";
import PropTypes from "prop-types";

const BulletIcon = ({ height, width }) => {
  return (
    <svg width={width || "19px"} height={height || "19px"} viewBox="0 0 19 19">
      <g
        id="Desktop"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Fellowship---BY1"
          transform="translate(-590.000000, -2974.000000)"
          fill="#B4B4B4"
        >
          <g id="Icon/Bullet" transform="translate(590.000000, 2974.000000)">
            <rect
              id="Bullet"
              transform="translate(9.500000, 9.500000) rotate(45.000000) translate(-9.500000, -9.500000) "
              x="3"
              y="3"
              width="13"
              height="13"
            ></rect>
          </g>
        </g>
      </g>
    </svg>
  );
};

BulletIcon.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

export default BulletIcon;
