import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import CourseSyllabus from "../../CourseDetail/child-components/CourseSyllabus/CourseSyllabus";
import PDFDownloadButton from "../../shared/PDFDownloadButton/PDFDownloadButton";

import { theme } from "../../../utils/global-styles/theme";

const MarketingCourseInfo = ({
  syllabusData,
  pdfCta,
  onCtaClick,
  ctaLinkText,
}) => {
  const CtaButtonContainer = styled.div`
      grid-area: 1 / 3 / span 1 / span 1;
      padding-top: 50px;

      @media only screen and (max-width: ${theme.breakpoints.desktopUp}) {
        grid-area: 2 / 1 / span 1 / span 1;
      }

      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        padding: 0 10px;
      }
    `,
    CtaButton = styled.div`
      height: 56px;
      width: 189px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      font-weight: bold;
      letter-spacing: 0.68px;
      line-height: 15px;
      margin-left: 48px;
      cursor: pointer;

      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        margin-left: 30px;
      }

      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        margin-left: 0px;
        margin-top: 13px;
        width: 100%;
      }
    `;
  const filteredData = syllabusData.filter(datum => {
    return datum.__typename === "ContentfulTitleDescription";
  });
  return (
    <CourseSyllabus
      courseSyllabus={filteredData}
      downloadButton={
        <PDFDownloadButton
          href={pdfCta ? pdfCta.url : null}
          linkText={pdfCta ? pdfCta.linkText : "Download Syllabus (PDF)"}
          buttonMarginLeft="48px"
        />
      }
      enrollButton={
        <CtaButtonContainer>
          {onCtaClick && (
            <CtaButton
              className="primary-bg white secondary-background-hover"
              onClick={() => onCtaClick()}
            >
              {ctaLinkText || "Enroll Now"}
            </CtaButton>
          )}
        </CtaButtonContainer>
      }
    />
  );
};

MarketingCourseInfo.propTypes = {
  syllabusData: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.shape({
        description: PropTypes.string,
      }),
    })
  ),
  pdfCta: PropTypes.shape({
    linkText: PropTypes.string,
    url: PropTypes.string,
  }),
  onCtaClick: PropTypes.func,
  ctaLinkText: PropTypes.string,
  buttonMarginLeft: PropTypes.string,
};

export default MarketingCourseInfo;
