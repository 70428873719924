import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

import { theme } from "../../../utils/global-styles/theme";

const FinancialAidBanner = ({
  hasGrayBackground,
  descriptionText,
  ctaLink,
}) => {
  const BannerGrid = styled.div`
      display: grid;
      grid-template: 1fr / 1fr 1440px 1fr;

      @media only screen and (max-width: ${theme.breakpoints.maxContent}) {
        grid-template: 1fr / 1fr;
      }
    `,
    BannerContentSubGrid = styled.div`
      grid-area: 1 / 2 / span 1 / span 1;
      display: grid;
      grid-template: 1fr / 1fr 52px;
      padding: 56px 120px;

      p,
      a {
        font-family: ${theme.fontFaces.headers};
      }

      @media only screen and (max-width: ${theme.breakpoints.maxContent}) {
        grid-area: 1 / 1 / span 1 / span 1;
      }
      @media only screen and (max-width: ${theme.breakpoints.helper1350}) {
        display: block;
      }
      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        padding: 56px 60px;
      }
      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        padding: 20px 14px;
      }
    `,
    DescriptionText = styled.p`
      grid-area: 1 / 1 / span 1 / span 1;
      font-size: 20px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 24px;
      display: flex;
      align-items: center;

      @media only screen and (max-width: ${theme.breakpoints.helper1350}) {
        margin-bottom: 10px;
      }

      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        margin-bottom: 7px;
        font-size: 14px;
        letter-spacing: -0.22px;
        line-height: 17px;
      }
    `,
    CtaLink = styled.a`
      grid-area: 1 / 2 / span 1 / span 1;
      font-size: 17px;
      font-weight: bold;
      letter-spacing: -0.37px;
      line-height: 20px;
      border-bottom: 4px solid;
      text-transform: uppercase;
      cursor: pointer;

      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        font-size: 13px;
        letter-spacing: -0.28px;
        line-height: 16px;
      }
    `;
  return (
    <BannerGrid className={hasGrayBackground ? "light-bg" : "primary-bg"}>
      <BannerContentSubGrid>
        <DescriptionText className="white secondary-border-hover">
          {descriptionText ||
            "If you are currently unable to afford the tuition, for whatever reason, apply for financial support here."}
        </DescriptionText>
        <CtaLink
          className="white secondary-border-hover"
          href={ctaLink.url}
          target="_blank"
        >
          {ctaLink.linkText || "apply"}
        </CtaLink>
      </BannerContentSubGrid>
    </BannerGrid>
  );
};

FinancialAidBanner.propTypes = {
  hasGrayBackground: PropTypes.bool,
  descriptionText: PropTypes.string,
  ctaLink: PropTypes.shape({
    linkText: PropTypes.string,
    url: PropTypes.string,
  }),
};

export default FinancialAidBanner;
