import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/core";

const DownloadIcon = ({ width, height, classNames, styles }) => {
  return(
    <svg 
      width={width || "18px"}
      height={height || "18px"}
      viewBox="0 0 18 18"
      className={classNames || "path-primary-fill"}
      css={css`
        ${styles}
      `}
    >
      <g id="Icons-&amp;-Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g 
          id="Icons"
          transform="translate(-976.000000, -347.000000)"
          fillRule="nonzero"
        >
          <g id="Shape-2" transform="translate(976.000000, 347.000000)">
            <path 
              d="M16,10 L16,15 C16,15.55 15.55,16 15,16 L3,16 C2.45,16 2,15.55 2,15 L2,10 C2,9.45 1.55,9 1,9 C0.45,9 0,9.45 0,10 L0,16 C0,17.1 0.9,18 2,18 L16,18 C17.1,18 18,17.1 18,16 L18,10 C18,9.45 17.55,9 17,9 C16.45,9 16,9.45 16,10 Z M10,9.67 L11.88,7.79 C12.27,7.4 12.9,7.4 13.29,7.79 C13.68,8.18 13.68,8.81 13.29,9.2 L9.7,12.79 C9.31,13.18 8.68,13.18 8.29,12.79 L4.7,9.2 C4.31,8.81 4.31,8.18 4.7,7.79 C5.09,7.4 5.72,7.4 6.11,7.79 L8,9.67 L8,1 C8,0.45 8.45,0 9,0 C9.55,0 10,0.45 10,1 L10,9.67 Z"
              id="Shape"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

DownloadIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  classNames: PropTypes.string,
  styles: PropTypes.string,
};

export default DownloadIcon;
