import React from "react";
import PropTypes from "prop-types";
import { theme } from "../../../utils/global-styles/theme";
import styled from "@emotion/styled";
import DownloadIcon from "../../shared/DownloadIcon";

const PDFDownloadButton = ({
  classNames,
  href,
  linkText,
  buttonMarginLeft,
}) => {
  
  const ApplicationToolkitWrapper = styled.div`
      display: flex;
      align-items: center;
      margin: 30px 15em;

      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        margin: 2em 0;
        padding: 0 10px;
        justify-content: center;
      }
    `,
    ApplicationToolkitLink = styled.a`
      border: 2px solid ${theme.colors.main};
      text-transform: uppercase;
      padding: 10px 10px 10px 10px;
      font-family: ${theme.fontFaces.headers};
      font-weight: bold;

      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        margin-left: 30px;
      }

      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        margin-left: 10px;
        margin-right: 10px;
      }
    `;
  return (
    <ApplicationToolkitWrapper>
      <ApplicationToolkitLink
        className={classNames || "primary primary-border primary-hover"}
        href={href}
        target="_blank"
      >
        <DownloadIcon styles={"margin-right: 10px;"} />
        {linkText}
      </ApplicationToolkitLink>
    </ApplicationToolkitWrapper>
  );
};

PDFDownloadButton.propTypes = {
  classNames: PropTypes.string,
  href: PropTypes.string,
  linkText: PropTypes.string,
};

export default PDFDownloadButton;
