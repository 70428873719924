import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { theme } from "../../../utils/global-styles/theme";
import Markdown from "markdown-to-jsx";

const MarketingCtaSection = ({ description, link, onCtaClick }) => {
  const SectionContainer = styled.section`
      display: grid;
      grid-template: 75px auto auto 75px / 140px auto 240px;
      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        grid-template: 30px auto auto 30px / 10px auto 10px;
      }
    `,
    StyledMarkdown = styled(Markdown)`
      grid-area: 2 / 2 / 2 / 2;
      h1 {
        padding-bottom: 75px;
        line-height: 1em;
      }
      p {
        font-family: ${theme.fontFaces.agDemiMd};
        font-size: 2em;
        line-height: 1em;
        padding-bottom: 40px;
        @media only screen and (max-width: ${theme.breakpoints.mobile}) {
          font-size: 1.5em;
          line-height: 1.2em;
        }
      }
      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        h1 {
          padding: 0 4px;
          padding-bottom: 30px;
        }
        p {
          padding: 0 4px;
        }
      }
    `,
    LinkContainer = styled.div`
      grid-area: 3 / 2 / 3 / 2;
      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        padding: 0 4px;
      }
    `,
    StyledButton = styled.button`
      font-family: ${theme.fontFaces.headers};
      font-size: 24px;
      font-weight: bold;
      letter-spacing: 0.68px;
      line-height: 15px;
      height: 56px;
      width: 209px;
      margin-top: 20px;
      border: none;
      cursor: pointer;
      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        width: 100%;
      }
    `;

  // TODO: Wire in the expected behaviour of the button C.O 22/04/2020
  return (
    <SectionContainer className="secondary-bg white">
      <StyledMarkdown>{description.description}</StyledMarkdown>
      <LinkContainer>
        {onCtaClick && (
          <StyledButton
            className="primary-bg white"
            onClick={() => onCtaClick()}
          >
            {link.linkText}
          </StyledButton>
        )}
      </LinkContainer>
    </SectionContainer>
  );
};

MarketingCtaSection.propTypes = {
  link: PropTypes.shape({
    linkText: PropTypes.string,
    url: PropTypes.string,
  }),
  description: PropTypes.shape({
    description: PropTypes.string,
  }),
  onCtaClick: PropTypes.func,
};

export default MarketingCtaSection;
