import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import Img from "gatsby-image";

import { theme } from "../../utils/global-styles/theme";

// import useAffiliateBubbleSliderData from "../../hooks/affiliate-bubble-slider-data";
import Bubble from "../BubbleSlider/child-components/Bubble";

const AffiliateBubbleSlider = ({
  contentMultiple,
  marginTop,
  affiliateBubbleSliderData,
}) => {
  // const bubbleSliderData = useAffiliateBubbleSliderData(),
  const affiliateLogosArrayLength =
    affiliateBubbleSliderData?.carouselItems &&
    affiliateBubbleSliderData?.carouselItems.length;
  const [isMobileView, setMobileView] = useState(false),
    [currentStartIndex, setCurrentStartIndex] = useState(0),
    [activeBubbleIndex, setActiveBubbleIndex] = useState(0),
    [logosRemaining, setLogosRemaining] = useState(
      affiliateLogosArrayLength || 0
    ),
    manuallyChangeSlide = index => {
      setCurrentStartIndex(index * contentMultiple);
      setActiveBubbleIndex(index);
      setLogosRemaining(affiliateLogosArrayLength - index * contentMultiple);
    },
    handleResize = () => {
      if (window.innerWidth < 1351) {
        setMobileView(true);
        setCurrentStartIndex(0);
        setActiveBubbleIndex(0);
      } else {
        setMobileView(false);
      }
    };

  // contentMultiple will be reassigned to the length of affiliate array in MobileView.
  contentMultiple =
    isMobileView && affiliateLogosArrayLength
      ? affiliateLogosArrayLength
      : contentMultiple;

  useEffect(() => {
    // If executed server-side (no window object) it will return false(exit) out of the useEffect() hook
    if (typeof window !== "object" || affiliateLogosArrayLength === undefined) {
      return false;
    }

    window.addEventListener("resize", handleResize);

    if (isMobileView) {
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    } else {
      const timerId = setTimeout(() => {
        if (logosRemaining > contentMultiple) {
          setCurrentStartIndex(currentStartIndex + contentMultiple);
          setActiveBubbleIndex(activeBubbleIndex + 1);
          setLogosRemaining(logosRemaining - contentMultiple);
        } else if (logosRemaining <= contentMultiple && logosRemaining > 0) {
          setCurrentStartIndex(0);
          setActiveBubbleIndex(0);
          setLogosRemaining(affiliateLogosArrayLength);
        }
      }, 5000);
      return () => {
        window.removeEventListener("resize", handleResize);
        clearTimeout(timerId);
      };
    }
  });

  const buildBubbles = (totalCount, activeIndex, setActive) => {
    const bubbles = [];
    for (let i = 0; i < totalCount; i++) {
      bubbles.push(
        <Bubble
          active={activeIndex === i}
          setActive={setActive}
          index={i}
          key={i}
        />
      );
    }
    return bubbles;
  };

  const { heading, carouselDescription } = affiliateBubbleSliderData;

  const BubbleSliderGrid = styled.section`
      height: 304px;
      display: grid;
      grid-template-rows: 1fr 20%;
      grid-template-columns: 25% 1fr;
      color: ${theme.colors.white};
      font-family: ${theme.fontFaces.headers};
      margin-top: 6.5em;

      @media only screen and (max-width: ${theme.breakpoints.helper1350}) {
        display: block;
        height: max-content;
        margin-top: 0;
      }
    `,
    TitleContainer = styled.div`
      grid-area: 1 / 1 / span 1 / span 1;
      padding-top: 3em;
      padding-left: 5em;

      h2 {
        font-size: 3.25em;
        font-weight: bold;
        letter-spacing: -1.38px;
        line-height: 1em;

        @media only screen and (max-width: ${theme.breakpoints.mobile}) {
          font-size: 2.5em;
        }
      }

      h3 {
        opacity: 0.5;
        font-size: 17px;
        font-weight: bold;
        line-height: 1.25em;

        @media only screen and (max-width: ${theme.breakpoints.mobile}) {
          font-size: 10px;
        }
      }

      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        ${theme.paddings.mobileLR}
        padding-top: 2em;
      }
    `,
    AffiliateLogosContainer = styled.div`
      grid-area: 1 / 2 / span 1 / span 1;
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding-top: 2em;

      -webkit-animation: fadein 2s ease-in-out; /* Safari, Chrome and Opera > 12.1 */
      -moz-animation: fadein 2s ease-in-out; /* Firefox < 16 */
      -ms-animation: fadein 2s ease-in-out; /* Internet Explorer */
      -o-animation: fadein 2s ease-in-out; /* Opera < 12.1 */
      animation: fadein 2s ease-in-out;
      @media only screen and (max-width: ${theme.breakpoints.helper1350}) {
        flex-wrap: wrap;
        padding: 1em 0;
      }
      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        flex-wrap: wrap;
        padding: 0;
      }
    `,
    LogoContainer = styled.div`
      height: 160px;
      width: 160px;
      display: flex;
      justify-content: center;
      align-items: center;
    `,
    PaginationDotsContainer = styled.div`
      grid-area: 2/ 1 / span 1 / span 2;
      display: flex;
      justify-content: center;

      @media only screen and (max-width: ${theme.breakpoints.helper1350}) {
        display: none;
      }
    `;

  return affiliateLogosArrayLength ? (
    <BubbleSliderGrid
      className="secondary-bg"
      css={
        marginTop
          ? css`
              margin-top: ${marginTop};
            `
          : null
      }
    >
      <TitleContainer>
        <h2>{heading}</h2>
        <h3>{carouselDescription?.toUpperCase()}</h3>
      </TitleContainer>
      <AffiliateLogosContainer>
        {affiliateBubbleSliderData?.carouselItems
          .filter(
            (affiliateLogo, index) =>
              index >= currentStartIndex &&
              index < currentStartIndex + contentMultiple
          )
          .map((affiliateLogo, index) => {
            return (
              <LogoContainer key={index}>
                <Img
                  fluid={affiliateLogo.image.fluid}
                  css={css`
                    height: 200px / ${affiliateLogo.image.fluid.aspectRatio};
                    width: 200px;

                    @media only screen and (max-width: ${theme.breakpoints
                        .mobile}) {
                      height: 120px / ${affiliateLogo.image.fluid.aspectRatio};
                      width: 120px;
                    }
                  `}
                  className="helper1350-breakpoint-1em-margin"
                />
              </LogoContainer>
            );
          })}
        {affiliateLogosArrayLength % 2 !== 0 && isMobileView && (
          <LogoContainer></LogoContainer>
        )}
      </AffiliateLogosContainer>
      <PaginationDotsContainer>
        {buildBubbles(
          affiliateLogosArrayLength / contentMultiple,
          activeBubbleIndex,
          manuallyChangeSlide
        )}
      </PaginationDotsContainer>
    </BubbleSliderGrid>
  ) : null;
};

AffiliateBubbleSlider.propTypes = {
  contentMultiple: PropTypes.number,
  marginTop: PropTypes.string,
};

export default AffiliateBubbleSlider;
