import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { BLOCKS } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import get from "lodash/get";
import { theme } from "../../../utils/global-styles/theme";
import Img from "gatsby-image";

const MarketingRichText = ({
  richText,
  assets,
  richTextNumber,
  onCtaClick,
}) => {
  const gridTemplate =
      richTextNumber % 2 === 1
        ? "1fr auto 50px / 1.5fr 2fr 50px"
        : "1fr auto 50px / 50px 2.5fr 2fr",
    textGridArea =
      richTextNumber % 2 === 1
        ? "2 / 2 / span 2 / span 1"
        : "2 / 2 / span 2 / span 1",
    RichTextContainer = styled("div")`
      padding: 90px 50px 0px 50px;
      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        padding: 20px 0 0 0;
      }
    `,
    Header = styled("h1")`
      padding-left: 40px;
      margin-bottom: -54px;

      @media only screen and (max-width: ${theme.breakpoints.desktopUp}) {
        font-size: 100px;
        font-weight: bold;
        letter-spacing: -4.81px;
        line-height: 100px;
        margin-bottom: -40px;
      }
      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        font-size: 40px;
        letter-spacing: -1.92px;
        line-height: 48px;
        margin-bottom: -20px;
        padding: 0 10px;
      }
    `,
    Container = styled.div`
      width: 100%;
      display: grid;
      grid-template: ${gridTemplate};
      margin-bottom: 50px;

      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        grid-template: auto auto / 100%;
      }
    `,
    Image = styled(Img)`
      position: absolute;
      z-index: -1;
      grid-area: 1 / 1 / span 2 / span 3;
      max-height: 644px;
      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        max-height: 100%;
        grid-area: 1 / 1 / span 1 / span 1;
        margin: 0 10px;
      }
    `,
    SuperpositionedTextContainer = styled.div`
      grid-area: ${textGridArea};
      padding: 50px;
      z-index: 1;
      display: flex;
      align-self: flex-end;
      height: max-content;

      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        grid-area: 2 / 1 / span 1 / span 1;
        padding: 30px 14px 30px 30px;
      }
    `,
    TextContainer = styled.div`
      max-width: 787px;
      margin: 0px auto 50px auto;
      page-break-inside: avoid;

      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        margin: 0px 14px 30px 14px;
      }
    `,
    Text = styled.p`
      font-size: 20px;
      line-height: 31px;
    `,
    StyledButton = styled.button`
      font-family: ${theme.fontFaces.headers};
      height: 56px;
      width: 189px;
      font-size: 24px;
      font-weight: bold;
      letter-spacing: 0.68px;
      line-height: 15px;
      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        width: 100%;
        margin-bottom: 0px;
      }
    `;

  const images = assets.map(edge => edge.node.fluid);
  const richTextOptions = {
    renderNode: {
      [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
        if (node.data.target.sys.contentType.sys.id === "image") {
          const { file } = get(
            node,
            "data.target.fields.image.en-US.fields",
            {}
          );

          return (
            /**
             * Select the fluid properties from the image assets that have the
             * same imageId found in the url
             */
            <Container>
              <Image
                fluid={images.find(image =>
                  image.srcSetWebp.includes(file["en-US"].url.split("/")[4])
                )}
              />
              <SuperpositionedTextContainer className="primary-bg white">
                <h3>{node.data.target.fields.imageCaption["en-US"]}</h3>
              </SuperpositionedTextContainer>
            </Container>
          );
        } else if (node.data.target.sys.contentType.sys.id === "link") {
          const text = node.data.target.fields.linkText["en-US"];

          return (
            <TextContainer>
              {onCtaClick && (
                <StyledButton
                  className="primaryBtn"
                  onClick={() => onCtaClick()}
                >
                  {text}
                </StyledButton>
              )}
            </TextContainer>
          );
        }
      },
      [BLOCKS.HEADING_1]: (node, children) => {
        return <Header>{children}</Header>;
      },
      [BLOCKS.HEADING_3]: (node, children) => {
        return (
          <TextContainer>
            <h3 className="primary">{children}</h3>
          </TextContainer>
        );
      },
      [BLOCKS.PARAGRAPH]: (node, children) => {
        return (
          <TextContainer>
            <Text>{children}</Text>
          </TextContainer>
        );
      },
    },
  };

  return (
    <RichTextContainer>
      {documentToReactComponents(richText.json, richTextOptions)}
    </RichTextContainer>
  );
};

MarketingRichText.propTypes = {
  richText: PropTypes.object,
  assets: PropTypes.shape({
    node: PropTypes.shape({
      fluid: PropTypes.shape({
        aspectRatio: PropTypes.number,
        srcSetWebp: PropTypes.string,
      }),
    }),
  }),
  richTextNumber: PropTypes.number,
  onCtaClick: PropTypes.func,
};

export default MarketingRichText;
