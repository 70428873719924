import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import Img from "gatsby-image";
import Markdown from "markdown-to-jsx";

import { theme } from "../../../utils/global-styles/theme";

const PromoBannerLong = ({
  hasGrayBackground,
  promoImage,
  promoDescription,
  ctaLink,
  onCtaClick,
}) => {
  const promoImageAspectRatio =
    promoImage.fluid && promoImage.fluid.aspectRatio
      ? promoImage.fluid.aspectRatio
      : 1;

  const PromoBannerGrid = styled.div`
      display: grid;
      grid-template: 1fr / 1fr 1440px 1fr;

      @media only screen and (max-width: ${theme.breakpoints.maxContent}) {
        grid-template: 1fr / 1fr;
      }
    `,
    PromoBannerContentSubGrid = styled.div`
      grid-area: 1 / 2 / span 1 / span 1;
      display: grid;
      grid-template: 1fr / auto 1fr 230px;
      padding: 36px 0;

      @media only screen and (max-width: ${theme.breakpoints.maxContent}) {
        grid-area: 1 / 1 / span 1 / span 1;
      }

      @media only screen and (max-width: ${theme.breakpoints.desktopUp}) {
        grid-template: 1fr / auto 1fr 180px;
      }

      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        grid-template: 1fr / auto 1fr;
      }

      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        padding: 20px 14px;
      }
    `,
    LeftPromoSubSection = styled.div`
      grid-area: 1 / 1 / span 1 / span 1;
      padding: 0 120px;

      @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
        padding: 0 60px;
      }
      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        padding: 0 20px 0 0;
      }
    `,
    PromoImg = styled(Img)`
      height: 139px;
      width: ${139 * promoImageAspectRatio}px;
    `,
    RightPromoSubSection = styled.div`
      grid-area: 1 / 2 / span 1 / span 1;
      padding-top: 44px;
      padding-right: 20px;

      @media only screen and (max-width: ${theme.breakpoints.desktopUp}) {
        padding-top: 0;
      }
    `,
    StyledMarkdown = styled(Markdown)`
      p {
        font-family: ${theme.fontFaces.headers};
        &:first-of-type {
          font-size: 24px;
          font-weight: 500;
          letter-spacing: -0.22px;
          line-height: 30px;

          @media only screen and (max-width: ${theme.breakpoints.mobile}) {
            max-width: 100%;
            font-size: 15px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 18px;
          }
        }
        &:last-of-type {
          font-family: ${theme.fontFaces.headers};
          margin-top: 7px;
          font-size: 12px;
          font-weight: 500;
          letter-spacing: 0.34px;
          line-height: 14px;

          @media only screen and (max-width: ${theme.breakpoints.mobile}) {
            margin-top: 10px;
            font-size: 10px;
            font-weight: 500;
            letter-spacing: 0.28px;
            line-height: 12px;
          }
        }
        strong {
          font-weight: bold;
          font-size: 26px;

          @media only screen and (max-width: ${theme.breakpoints.mobile}) {
            font-size: 17px;
          }
        }
        em {
          font-style: italic;
        }
      }
    `,
    CtaLinkContainer = styled.div`
      grid-area: 1 / 3 / span 1 / span 1;
      padding-top: 44px;

      @media only screen and (max-width: ${theme.breakpoints.desktopUp}) {
        padding-top: 0;
      }
    `,
    CtaLinkText = styled.p`
      font-family: ${theme.fontFaces.headers};
      width: max-content;
      font-size: 17px;
      font-weight: bold;
      letter-spacing: -0.37px;
      line-height: 20px;
      border-bottom: 4px solid;
      text-transform: uppercase;
      cursor: pointer;

      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        font-size: 13px;
        letter-spacing: -0.28px;
        line-height: 16px;
      }
    `;

  return (
    <PromoBannerGrid className={hasGrayBackground ? "light-bg" : ""}>
      <PromoBannerContentSubGrid>
        <LeftPromoSubSection>
          {promoImage && promoImage.fluid && promoImage.fluid.srcSet && (
            <PromoImg fluid={promoImage.fluid} />
          )}
        </LeftPromoSubSection>
        <RightPromoSubSection>
          {promoDescription && (
            <StyledMarkdown>{promoDescription}</StyledMarkdown>
          )}
          {ctaLink && ctaLink.linkText && (
            <CtaLinkText
              className="primary secondary-border-hover"
              css={css`
                display: none;
                @media only screen and (max-width: ${theme.breakpoints
                    .helper800}) {
                  display: block;
                  margin-top: 10px;
                }
              `}
              onClick={() => onCtaClick("purchaseBookPromoSection")}
            >
              {ctaLink.linkText}
            </CtaLinkText>
          )}
        </RightPromoSubSection>
        <CtaLinkContainer
          css={css`
            @media only screen and (max-width: ${theme.breakpoints.helper800}) {
              display: none;
            }
          `}
        >
          {ctaLink && ctaLink.linkText && (
            <CtaLinkText
              className="primary secondary-border-hover"
              onClick={() => onCtaClick("purchaseBookPromoSection")}
            >
              {ctaLink.linkText}
            </CtaLinkText>
          )}
        </CtaLinkContainer>
      </PromoBannerContentSubGrid>
    </PromoBannerGrid>
  );
};

PromoBannerLong.propTypes = {
  hasGrayBackground: PropTypes.bool,
  promoImage: PropTypes.shape({
    fluid: PropTypes.shape({
      srcSet: PropTypes.string,
      aspectRatio: PropTypes.number,
    }),
  }),
  promoDescription: PropTypes.string,
  ctaLink: PropTypes.shape({
    linkText: PropTypes.string,
    url: PropTypes.string,
  }),
  secondCtaLink: PropTypes.shape({
    linkText: PropTypes.string,
    url: PropTypes.string,
  }),
  allProceedsText: PropTypes.string,
  onCtaClick: PropTypes.func,
};

export default PromoBannerLong;
