import React, { useState } from "react";
import { graphql } from "gatsby";
import get from "lodash/get";

import { navigate } from "gatsby";

import Layout from "../components/Layout/Layout";
import MarketingHeroHeader from "../components/MarketingLander/child-components/MarketingHeroHeader";
import MarketingCourseInfo from "../components/MarketingLander/child-components/MarketingCourseInfo";
import PromoBanner from "../components/MarketingLander/child-components/PromoBanner";
import PromoBannerLong from "../components/MarketingLander/child-components/PromoBannerLong";
import FinancialAidBanner from "../components/MarketingLander/child-components/FinancialAidBanner";
import TestimonialSlider from "../components/CourseDetail/child-components/TestimonialSlider";
import AffiliateBubbleSlider from "../components/AffiliationBubbleSlider/AffiliateBubbleSlider";
import BulletedLearnList from "../components/CourseDetail/child-components/BulletedLearnList";
import PurchaseBookBanner from "../components/MarketingLander/child-components/PurchaseBookBanner";
import MarketingCtaSection from "../components/MarketingLander/child-components/MarketingCtaSection";
import MarketingRichText from "../components/MarketingLander/child-components/MarketingRichText";

import EnrollmentNotification from "../components/CourseDetail/child-components/EnrollmentNotification";
import useEnrollmentNotificationData from "../hooks/enrollment-notification-data";

import { _post } from "../utils/services/api.service";
import {
  getUserDetails,
  isLoggedIn,
  saveUserDetails,
} from "../utils/services/methods";

// what component to use, by sectionIdentifier
const ComponentSemaphore = {
  "hero-section": null, //landingPageHeroSection [AA-716]
  "promo-banner-section": null, //titleAndDescription [AA-716]
  "what-youll-learn-section": null, //landingPageWhatYoullLearn [AA-717]
  //Social proof logos
  "social-proof-section": null, //CarouselContent [AA-718]
  "testimonials-section": null, //CarouselContent [AA-719]
  // "Proven, Powerful, and Inspiring" "For oganization and for individuals" "Find the others"
  // "Course Description"
  "richtext-section": null, //RichTextModule [AA-720]
  "course-syllabus-section": null, //CarouselContent [AA-721]
  // "Buy the book"
  "purchase-promo-section": null, //TitleAndDescription [AA-722]
  // "The world needs you to lead"
  "cta-section": null, //TitleAndDescription [AA-723]
};

/**
 * Used to alternate the position of the superpositioned text
 * of the rich text sections
 */
let richTextNumber = 0;

const renderPageSection = (
  section,
  index,
  handleEnrollmentClick,
  assets,
  displayOption,
  scrollTop,
  scrollToId
) => {
  switch (section.sectionIdentifier) {
    case "hero-section":
      return (
        <MarketingHeroHeader
          index={index}
          heading={get(section, "heading", null)}
          heroImage={get(section, "heroImage", null)}
          videoPreviewThumbnail={get(section, "videoThumbnail", null)}
          videoPreviewUrl={get(
            section,
            "videoPreviewUrl",
            "https://player.vimeo.com/video/106505300"
          )}
          themeName={get(section, "theme.theme", "Master Innovation")}
          themeNumber={get(section, "theme.themeNumber", 5)}
          courseDescription={get(
            section,
            "courseDescription.courseDescription",
            null
          )}
          courseCost={get(section, "courseCost", null)}
          courseCostText={get(section, "courseCostText", null)}
          courseStartDate={get(section, "courseStartDate")}
          courseDuration={get(section, "courseDuration", null)}
          ctaLinkText={get(section, "cta.linkText", "Enroll Now")}
          ctaUrl={get(section, "cta.url", null)}
          form={get(section, "form", null)}
          handleEnrollmentClick={handleEnrollmentClick}
          displayOption={displayOption}
        />
      );
    case "promo-banner-section":
      return (
        <PromoBanner
          hasGrayBackground={get(section, "hasGrayBackground", false)}
          promoImage={get(section, "image", null)}
          promoDescription={get(section, "description.description", null)}
          ctaLink={get(section, "link", null)}
          secondCtaLink={get(section, "secondLink", null)}
          allProceedsText={get(section, "factsHighlight", null)}
        />
      );
    case "promo-banner-section-long":
      return (
        <PromoBannerLong
          hasGrayBackground={get(section, "hasGrayBackground", false)}
          promoImage={get(section, "image", null)}
          promoDescription={get(section, "description.description", null)}
          ctaLink={get(section, "link", null)}
          onCtaClick={scrollToId}
        />
      );
    case "financial-aid":
      return (
        <FinancialAidBanner
          hasGrayBackground={get(section, "hasGrayBackground", false)}
          descriptionText={get(section, "description.description", null)}
          ctaLink={get(section, "link", null)}
        />
      );
    case "testimonials-section":
      return (
        <TestimonialSlider
          testimonials={get(section, "carouselItems", null)}
          hasGrayBackground={get(section, "hasGrayBackground", false)}
        />
      );
    case "social-proof-section":
      return <AffiliateBubbleSlider contentMultiple={3} marginTop="0px" />;
    case "what-youll-learn-section":
      return (
        <BulletedLearnList
          learningBullets={get(section, "bulletPoints", null)}
          hasGrayBackground={get(section, "hasGrayBackground", false)}
          heading={get(section, "heading", false)}
          subheading={get(section, "subheading", false)}
        />
      );
    case "purchase-promo-section":
      return (
        <PurchaseBookBanner
          hasGrayBackground={get(section, "hasGrayBackground", false)}
          promoImage={get(section, "image", null)}
          promoDescription={get(section, "description.description", null)}
          ctaLink={get(section, "link", null)}
          secondCtaLink={get(section, "secondLink", null)}
          allProceedsText={get(section, "factsHighlight", null)}
        />
      );
    case "richtext-section":
      richTextNumber += 1;
      return (
        <MarketingRichText
          assets={assets.edges}
          richText={get(section, "richText", null)}
          richTextNumber={richTextNumber}
          onCtaClick={
            displayOption && displayOption.toLowerCase() === "form"
              ? scrollTop
              : handleEnrollmentClick
          }
        />
      );
    case "cta-section":
      return (
        <MarketingCtaSection
          description={get(section, "description", null)}
          link={get(section, "link", null)}
          onCtaClick={
            displayOption && displayOption.toLowerCase() === "form"
              ? scrollTop
              : handleEnrollmentClick
          }
        />
      );
    case "course-syllabus-section":
      const syllabusData = get(section, "carouselItems", null);
      const pdfCta = get(section, "cta", null);
      return (
        <MarketingCourseInfo
          syllabusData={syllabusData}
          pdfCta={pdfCta}
          displayOption={displayOption}
          onCtaClick={
            displayOption && displayOption.toLowerCase() === "form"
              ? scrollTop
              : handleEnrollmentClick
          }
        />
      );
    default:
      return null;
  }
};
const MarketingLanderPage = ({
  data: { page, assets },
  location: { pathname },
  pageContext: { language },
}) => {
  const [loading, setLoading] = useState(false),
    [enrollmentNotification, setEnrollmentNotification] = useState(null),
    enrollmentNotificationsData = useEnrollmentNotificationData();

  const heroSection =
    page.nodes[0].pageSections && Array.isArray(page.nodes[0].pageSections)
      ? page.nodes[0].pageSections.find(
          section => section.sectionIdentifier === "hero-section"
        )
      : null;

  const scrollTop = () => {
      window.scroll({ top: 0, left: 0, behavior: "smooth" });
    },
    scrollToId = id => {
      const yPosition =
        document.getElementById(id).getBoundingClientRect().top +
        window.pageYOffset;

      window.scroll({ top: yPosition, left: 0, behavior: "smooth" });
    },
    handleEnrollmentClick = heroSection
      ? async () => {
          setLoading(true);

          const {
            courseId,
            courseType,
            courseCost,
            courseAvailability,
            courseDuration,
            slug,
            heading,
            provider,
            providerUrl,
            theme,
          } = heroSection;

          if (courseCost.toLowerCase().trim() !== "free") {
            return navigate("/payment/", {
              state: {
                course: {
                  courseType,
                  courseId,
                  title: heading,
                  provider,
                  providerUrl,
                  courseTheme: theme.themeName,
                  courseCost,
                  courseAvailability,
                  courseDuration,
                  slug,
                },
              },
            });
          }
          if (isLoggedIn()) {
            const userDetails = getUserDetails(),
              {
                external_id,
                email,
                first_name,
                last_name,
                provider_id,
              } = userDetails;

            let courseEnrollment =
              provider_id === ""
                ? await _post({
                    endpoint: "enrollments",
                    args: {
                      external_id,
                      email,
                      first_name,
                      provider_url: providerUrl,
                      last_name,
                      course_id: courseId,
                      provider,
                      course_name: heading,
                    },
                  })
                : await _post({
                    endpoint: "enrollments",
                    args: {
                      external_id,
                      email,
                      first_name,
                      last_name,
                      course_id: courseId,
                      provider,
                      provider_url: providerUrl,
                      provider_id,
                      course_name: heading,
                    },
                  });

            if (
              courseEnrollment.success &&
              provider_id === "" &&
              provider.toUpperCase() === "THINKIFIC"
            ) {
              saveUserDetails({
                external_id,
                email,
                first_name,
                last_name,
                provider_id: courseEnrollment.data.user.provider_id,
              });
              // A future release of Chrome will only deliver cookies with cross-site requests if they are set with `SameSite=None` and `Secure`. - 1/15/20
            }

            setLoading(false);

            if (courseEnrollment.success) {
              setEnrollmentNotification("success");
              window.scrollTo({
                top: 0,
                left: 0,
              });
            } else {
              setEnrollmentNotification("fail");
              window.scrollTo({
                top: 0,
                left: 0,
              });
            }
          } else {
            localStorage.setItem(
              "pendingCourseEnrollmentDetails",
              JSON.stringify({
                courseType,
                courseId,
                provider,
                providerUrl,
                courseTheme: theme.themeName,
                title: heading,
                courseCost,
                courseAvailability,
                courseDuration,
                slug,
              })
            );
          }
        }
      : null;

  return (
    <Layout
      loading={loading}
      enforceWidth={false}
      isMarketingLanderTemplate={true}
      isDetailView={false}
    >
      {enrollmentNotification ? (
        <EnrollmentNotification
          setEnrollmentNotification={setEnrollmentNotification}
          notification={enrollmentNotification}
          notificationData={enrollmentNotificationsData}
        />
      ) : (
        page.nodes[0].pageSections &&
        Array.isArray(page.nodes[0].pageSections) &&
        page.nodes[0].pageSections.map((section, index) => {
          return renderPageSection(
            section,
            index,
            handleEnrollmentClick,
            assets,
            page.nodes[0].displayOption,
            scrollTop,
            scrollToId
          );
        })
      )}
    </Layout>
  );
};

export const query = graphql`
  query($queryIdentifier: String!, $images: [String!]!) {
    page: allContentfulLandingPageTemplate(
      filter: {
        queryIdentifier: { eq: $queryIdentifier }
        node_locale: { eq: "en-US" }
      }
    ) {
      nodes {
        displayOption
        pageSections {
          ... on ContentfulLandingPageHeroSection {
            sectionIdentifier
            heading
            heroImage {
              image {
                fluid {
                  aspectRatio
                  srcSet
                }
              }
            }
            languages {
              linkText
              url
            }
            courseDescription {
              courseDescription
            }
            slug
            provider
            providerUrl
            courseId
            courseAvailability
            courseType
            courseCost
            courseCostText
            courseStartDate
            courseDuration
            cta {
              linkText
              url
            }
            theme {
              themeNumber
              theme
            }
            videoThumbnail {
              fluid {
                aspectRatio
                srcSet
              }
            }
            form {
              heading
              formFields
              cta {
                linkText
              }
            }
            videoPreviewUrl
          }
          ... on ContentfulLandingPageWhatYoullLearn {
            sectionIdentifier
            hasGrayBackground
            heading
            subheading
            bulletPoints
          }
          ... on ContentfulCarouselContent {
            sectionIdentifier
            hasGrayBackground
            heading
            carouselItems {
              ... on ContentfulImage {
                image {
                  fluid {
                    aspectRatio
                    srcSet
                  }
                }
                imageDescription: description
              }
              ... on ContentfulTestimonialQuote {
                authorName
                authorTitle
                testimonialQuote {
                  testimonialQuote
                }
              }
              ... on ContentfulTitleDescription {
                title
                description {
                  description
                }
              }
            }
            cta {
              linkText
              url
            }
          }
          ... on ContentfulRichTextModule {
            sectionIdentifier
            hasGrayBackground
            richText {
              json
            }
          }
          ... on ContentfulTitleDescription {
            sectionIdentifier
            hasGrayBackground
            description {
              description
            }
            image {
              fluid {
                aspectRatio
                srcSet
              }
            }
            link {
              linkText
              url
            }
            secondLink {
              linkText
              url
            }
            factsHighlight
          }
        }
      }
    }
    assets: allContentfulAsset(filter: { file: { url: { in: $images } } }) {
      edges {
        node {
          fluid {
            aspectRatio
            srcSetWebp
          }
        }
      }
    }
  }
`;

export default MarketingLanderPage;
