import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import Img from "gatsby-image";
import Markdown from "markdown-to-jsx";

import { theme } from "../../../utils/global-styles/theme";

import Link4Arrow from "../../shared/Link4Arrow";

const PromoBanner = ({
  hasGrayBackground,
  promoImage,
  promoDescription,
  ctaLink,
  secondCtaLink,
  allProceedsText,
}) => {
  const promoImageAspectRatio =
    promoImage.fluid && promoImage.fluid.aspectRatio
      ? promoImage.fluid.aspectRatio
      : 1;

  const PromoBannerGrid = styled.div`
      display: grid;
      grid-template: 1fr / 1fr 1440px 1fr;

      @media only screen and (max-width: ${theme.breakpoints.maxContent}) {
        grid-template: 1fr / 1fr;
      }
    `,
    PromoBannerContentSubGrid = styled.div`
      grid-area: 1 / 2 / span 1 / span 1;
      display: grid;
      grid-template: 1fr / auto 1fr;
      padding: 36px 0;

      @media only screen and (max-width: ${theme.breakpoints.maxContent}) {
        grid-area: 1 / 1 / span 1 / span 1;
      }

      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        padding: 20px 14px;
      }
    `,
    LeftPromoSubSection = styled.div`
      grid-area: 1 / 1 / span 1 / span 1;
      padding: 0 120px;

      @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
        padding: 0 60px;
      }
      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        padding: 0 20px 0 0;
      }
    `,
    PromoImg = styled(Img)`
      height: 139px;
      width: ${139 * promoImageAspectRatio}px;
    `,
    RightPromoSubSection = styled.div`
      grid-area: 1 / 2 / span 1 / span 1;
    `,
    StyledMarkdown = styled(Markdown)`
      p {
        font-family: ${theme.fontFaces.headers};
        &:first-of-type {
          max-width: 80%;
          font-size: 24px;
          font-weight: 500;
          letter-spacing: -0.22px;
          line-height: 30px;

          @media only screen and (max-width: ${theme.breakpoints.mobile}) {
            max-width: 100%;
            font-size: 15px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 18px;
          }
        }
        &:nth-of-type(2) {
          margin-top: 10px;
          font-family: ${theme.fontFaces.headers};
          font-size: 17px;
          font-weight: 500;
          letter-spacing: -0.37px;
          line-height: 24px;

          @media only screen and (max-width: ${theme.breakpoints.mobile}) {
            display: none;
          }
        }
        strong {
          font-weight: bold;
          font-size: 26px;

          @media only screen and (max-width: ${theme.breakpoints.mobile}) {
            font-size: 17px;
          }
        }
        em {
          font-style: italic;
        }
      }
    `,
    AllProceedsText = styled.p`
      font-family: ${theme.fontFaces.headers};
      margin-top: 7px;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.34px;
      line-height: 14px;

      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        margin-top: 10px;
        font-size: 10px;
        font-weight: 500;
        letter-spacing: 0.28px;
        line-height: 12px;
      }
    `;

  return (
    <PromoBannerGrid className={hasGrayBackground ? "light-bg" : ""}>
      <PromoBannerContentSubGrid>
        <LeftPromoSubSection>
          {promoImage && promoImage.fluid && promoImage.fluid.srcSet && (
            <PromoImg fluid={promoImage.fluid} />
          )}
        </LeftPromoSubSection>
        <RightPromoSubSection>
          {promoDescription && (
            <StyledMarkdown
              options={{
                overrides: {
                  a: {
                    props: {
                      className: "primary",
                    },
                  },
                },
              }}
            >
              {promoDescription}
            </StyledMarkdown>
          )}
          {ctaLink && ctaLink.linkText && (
            <Link4Arrow
              fontStyles={css`
                margin-top: 7px;
                display: block;
                width: max-content;

                @media only screen and (max-width: ${theme.breakpoints
                    .mobile}) {
                  margin-top: 10px;
                }
              `}
              // TODO - Charles once we get the url values into Contentful we should change or remove the fallback link. - 4/22/20
              to={
                ctaLink.url ||
                "https://www.amazon.com/Manifesto-Moral-Revolution-Tools-Change-ebook/dp/B07R9S8JR7"
              }
              isExternalLink={true}
            >
              {ctaLink.linkText}
            </Link4Arrow>
          )}
          {secondCtaLink && secondCtaLink.linkText && (
            <Link4Arrow
              fontStyles={css`
                margin-top: 7px;
                display: block;
                width: max-content;

                @media only screen and (max-width: ${theme.breakpoints
                    .mobile}) {
                  margin-top: 0px;
                }
              `}
              // TODO - Charles once we get the url values into Contentful we should change or remove the fallback link. - 4/22/20
              to={
                secondCtaLink.url ||
                "https://www.bookdepository.com/search?searchTerm=Manifesto+for+a+Moral+Revolution&search=Find+book"
              }
              isExternalLink={true}
            >
              {secondCtaLink.linkText}
            </Link4Arrow>
          )}
          {allProceedsText && (
            <AllProceedsText>{allProceedsText}</AllProceedsText>
          )}
        </RightPromoSubSection>
      </PromoBannerContentSubGrid>
    </PromoBannerGrid>
  );
};

PromoBanner.propTypes = {
  hasGrayBackground: PropTypes.bool,
  promoImage: PropTypes.shape({
    fluid: PropTypes.shape({
      srcSet: PropTypes.string,
      aspectRatio: PropTypes.number,
    }),
  }),
  promoDescription: PropTypes.string,
  ctaLink: PropTypes.shape({
    linkText: PropTypes.string,
    url: PropTypes.string,
  }),
  secondCtaLink: PropTypes.shape({
    linkText: PropTypes.string,
    url: PropTypes.string,
  }),
  allProceedsText: PropTypes.string,
};

export default PromoBanner;
